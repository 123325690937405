import 'waypoints/lib/jquery.waypoints.js'

// SCSS
import './scss/main.scss';

var jQuery = $.noConflict();

var answered;

var quiz_id = 45;
var analytics_ref = 'Call Of Duty Black Ops Four';
var version_ref = '';
var session_log = '';

var this_site = '%%SITE%%';

var terms_list = [
  'The competition is open to UK entrants only',
  'Entrants must be aged 18yrs or older',
  'Employees of Gamer Network or Activision, or members of their family are not permitted to enter ',
  'The competition ends on the 17th September 2018 15:00PM BST',
  'In the event of more than one entrant winning, the overall prize winner will be selected at random',
  'The winner will be notified by email ',
  'The prizes will be dispatched within 30 days of the competition',
  'The prize games will be supplied as either physical or digital copies',
  'The judge\'s decision is final with winners picked at random',
  'By default, contact details will be stored and shared for the purposes of contacting winners only about delivering their prize.'
];

jQuery(document).ready(function () {
  
  getQuestions();

  function getQuestions() {
    jQuery.getJSON('https://gquiz.gamer-network.net/quizes/' + quiz_id + '/questions/all/?callback=?', function (
      data) {
      jQuery.each(data, updateQuestion);
    });
  }

  function countProperties(obj) {
    var count = 0;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        ++count;
    }
    return count;
  }

  let hasSelectedSide = false;

  function updateQuestion(n, q) {
    var question_html = '';
    question_html += '<div id="quiz_answers">';
    jQuery.each(q['answers'], function (i, content) {
      question_html += '<a src="#" class="btn ' + content.answer + '" data-answer-id="' + i + '"><div>' +
        '' + '</div></a>';
    });
    question_html += '</div>';
    let formHasOpened = false;

    jQuery('.gquiz').append(question_html);

    jQuery('.btn.Westie').mouseenter( function(){
      if(!sideHasBeenChosen) {
        jQuery('#competition-right').css({
          'filter': 'brightness(0.5)'
        })
        jQuery('#join-frags-button').css({
          'filter': 'brightness(0.5)'
        })
      }

    });

    jQuery('.btn.JackFrags').mouseenter( function(){
      if(!sideHasBeenChosen) {
        jQuery('#competition-left').css({
          'filter': 'brightness(0.5)'
        })
        jQuery('#join-westie-button').css({
          'filter': 'brightness(0.5)'
        })
      }
    });

    jQuery('.btn').mouseout(function(){
      if(!sideHasBeenChosen) {
        jQuery('#competition-left').css({
          'filter': 'brightness(1)'
        })
        jQuery('#competition-right').css({
          'filter': 'brightness(1)'
        })
        jQuery('.competition-button').css({
          'filter': 'brightness(1)'
        })
      }
    });
  
    let sideHasBeenChosen = false;

    jQuery('#quiz_answers a').on('click', function (e) {
      let westieUrl = 'https://www.youtube.com/embed/5vsCJ-sA4os?autoplay=1';
      let fragsUrl = 'https://www.youtube.com/embed/osmqKal_T28?autoplay=1'
  
      e.preventDefault();
      // sideHasBeenChosen = true;
      // window.scrollTo(0, 0);
      // window.scrollTo(0, 500);

      if(!formHasOpened) {
        if (jQuery(this).attr('class').indexOf('Westie') >= 0) {
          // jQuery('body').append('<style>#submit_form {border: 2px solid #3bbdbd !important;}</style>')
          // jQuery('#competition-right').css({
          //   'filter': 'brightness(0.5)'
          // })
          // jQuery('#join-frags-button').css({
          //   'filter': 'brightness(0.5)'
          // })
          // jQuery('#competition-left').css({
          //   'filter': 'brightness(1)'
          // })
          // jQuery('#join-westie-button').css({
          //   'filter': 'brightness(1)'
          // })
          gtag('event', 'Click Westie Livestream');
          jQuery.fancybox.open({
            type: 'iframe',
            href: westieUrl,
            maxWidth: 1262,
            maxHeight: 710,
            fitToView: false,
            width: '90%',
            height: '90%',
            autoSize: false,
            scrolling: 'no',
            padding: '0',
          });
        }
        if (jQuery(this).attr('class').indexOf('JackFrags') >= 0) {
          // jQuery('body').append('<style>#submit_form {border: 2px solid #a6242b !important;}</style>')
          // jQuery('#competition-left').css({
          //   'filter': 'brightness(0.5)'
          // })
          // jQuery('#join-westie-button').css({
          //   'filter': 'brightness(0.5)'
          // })
          // jQuery('#competition-right').css({
          //   'filter': 'brightness(1)'
          // })
          // jQuery('#join-frags-button').css({
          //   'filter': 'brightness(1)'
          // })
          gtag('event', 'Click JackFrags Livestream');
          jQuery.fancybox.open({
            type: 'iframe',
            href: fragsUrl,
            maxWidth: 1262,
            maxHeight: 710,
            fitToView: false,
            width: '90%',
            height: '90%',
            autoSize: false,
            scrolling: 'no',
            padding: '0',
          });
  
        }
      }

      // answered = jQuery(this).attr('data-answer-id');
      // session_log += '{' + n + ':' + answered + '},';
      // var user_score = 0;
      // if (q['answers'][answered]['mode'] == 1) {
      //   user_score = 1;
      // }
      // quizSubmit(user_score);
    });
    function quizSubmit(user_score) {
      var submit_html =
        '<div class="form-container"><form id="submit_form" action="/score" method="post"><input type="hidden" name="score" value="' +
        user_score + '"><input type="hidden" name="answers" value="' + session_log +
        '" /><input type="hidden" name="quiz_id" value="' + quiz_id +
        '" /><input type="hidden" name="option" value="0" /><input type="hidden" name="contact" value="1" /><input required type="text" class="username-control" placeholder="NAME" name="username" /><div class="email-wrapper"><input required type="email" class="email-control" placeholder="EMAIL" name="email" /><div class="terms-wrapper"><input required type="checkbox" class="policy" id="terms" /> <label for="terms">I agree to the <a href="#" id="open_terms">terms and conditions</a>.</label></div><div id="button-bg"><button type="submit" class="submit-btn btn-primary" id="quiz_submit_link">Submit</button></div></form></div></div>';
      
      if(!formHasOpened) {
        jQuery('.form-container').remove();
        jQuery('#competition').append(submit_html);
        formHasOpened = false;
      }
      
      if(jQuery(window).width() > 768) {
        jQuery('#competition-wrapper').css('margin-top', '2rem');
      } else {
        jQuery('#competition-wrapper').css('margin-top', '4rem');
      }




      jQuery('#open_terms').click(function (e) {
        e.preventDefault();
        var terms_html = '<ul>';
        for (var i = 0; i < terms_list.length; i++) {
          terms_html += '<li>' + terms_list[i] + '</li>';
        }
        terms_html += '</ul>';
        jQuery.fancybox.open({
          // type: 'inline',
          content: '<div class="terms-and-conditions"><h2 style="margin-bottom: 1rem;">Terms and Conditions</h2>' + terms_html +
            '</div>'
        });
      });

     

      jQuery('#submit_form').submit(function (event) {
        event.preventDefault();
        var form = jQuery(this);
        var url = 'https://gquiz.gamer-network.net/score';
        var posting = jQuery.post(
          url,
          form.serialize()
        );
        competitionEnd();
        posting.done(function (data) {
          competitionEnd(data);
        });
      });
    }
  }

  function competitionEnd(data) {
    var done_html =
      '<div class="submit-message"><h1>Thank you!</h1><h2>You are now entered into our prize draw, good luck!</h2></div>';
    jQuery('.form-container').html(done_html)
    ga('showcase.send', 'event', analytics_ref, 'Entered Competition - ' + version_ref, this_site);
  }

  function getParameterByName(name) {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
});

jQuery('#video-player').on('click', function(e){
  e.preventDefault();
  jQuery.fancybox.open({
    type: 'iframe',
    href: 'https://www.youtube.com/embed/BjiaMBk6rHk?autoplay=1',
    controls: 0,
    maxWidth: 1262,
    maxHeight: 710,
    fitToView: false,
    width: '90%',
    height: '90%',
    autoSize: false,
    scrolling: 'no',
    padding: '0',
  })
})

jQuery('#t-c ').on('click', function(e){
  e.preventDefault();
  var terms_html = '<ul>';
  for (var i = 0; i < terms_list.length; i++) {
    terms_html += '<li>' + terms_list[i] + '</li>';
  }
  terms_html += '</ul>';
  jQuery.fancybox.open({
    content: '<div class="terms-and-conditions"><h2 style="margin-bottom: 1rem;">Terms and Conditions</h2>' + terms_html +
      '</div>'
  });
});

let stepWaypoints = jQuery('.competition-step').waypoint({
  handler: function() {
    jQuery(this.element).css({
      'transform': 'translateX(0)'
    })
  }, 
  offset: '100%'
})






